export const TENANT_REDIRECTS = [
    '/zh',
    '/gb',
    '/de',
    '/es',
    '/it',
    '/kr',
    '/sg',
    '/au',
    '/pr',
    '/br',
    '/cr',
    '/ch-en',
    '/ca-en',
    '/mx',
    '/jp',
    '/sustainability',
];

export const video_redirect = {
    'ca-fr': {
        title: {
            value: "<h3>Vous quittez le site Web d'Edwards Lifesciences Canada.</h3>",
        },
        description: `Il se peut que vous accédiez à un contenu extérieur à Edwards Lifesciences Canada. Le contenu de ce site peut ne pas être conforme aux exigences légales en vigueur au Canada. Il est important de noter que certains produits figurant sur ce site peuvent ne pas être autorisés à la vente au Canada.`,
        accept: 'Continuer',
        decline: 'Retour',
        locale: 'ca-en',
        popup_style: 'warning',
        regex: /^.*(youtube\.com|wistia\.com).*$/,
    },
};

export const custom_release_locales = ['jp'];
export const custom_PR_Data = {
    jp: {
        domestic: '日本法人プレスリリース',
        global: '米国本社プレスリリース',
        backButton: '一覧に戻る',
        printCTA: '印刷',
    },
};
export const jp_press_release_disclaimer = {
    description: {
        value: '<p>この資料の原文は、米国エドワーズライフサイエンス社が日に発表した英語の報道資料です。以下の翻訳は、日本の報道機関向けに参考として提供するものです。本資料の内容・解釈については、英語版の原文を参照してください。また、文中には日本では未承認、または適応外使用となる製品についての記述が含まれることがあります。</p><p>英語版は&nbsp;<a href="http://www.edwards.com/newsroom/press-releases">http://www.edwards.com/newsroom/press-releases</a>&nbsp;からご覧ください。</p>',
    },
};

export const page_title = {
    default: 'Edwards Lifesciences',
    benmakusho: '心臓弁膜症サイト',
    tavi_web: 'TAVIの情報サイト',
    newheartvalve_sea: 'newheartvalve.asia',
};
